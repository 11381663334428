import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { firebaseLogin, login, logout } from '@/src/api/endpoints/auth';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import { UserContext } from '@/src/context/user.context';
import { handleLanguageOnLogin } from '@/src/utilities/helpers/authHelpers';
import { errorHandlerHelper } from '@/src/utilities/helpers/errorHandlerHelper';
import { cleanStorage, setUserStorageData } from '@/src/utilities/storage';
import { AuthContext } from '../../context/auth.context';
import { GetMenusResponse, LoginProps } from './types';
import { mfaChallenge } from '@/src/api/endpoints/accounts-security';
import { FirebaseContext } from '@/src/context/firebase.context';
import { MenuItem } from '@/src/utilities/helpers/commonTypes';
import { LoginResponse } from '@/src/api/types/types';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';

export const useLogin = ({ prevUrl, isPasswordChanged }: LoginProps) => {
  const navigate = useNavigate();
  const { setUser, setMenus } = useContext(UserContext);
  const { setLoginStatus, setNoUserAllowedErrorMessage, noUserAllowedErrorMessage, setPrevUrl } =
    useContext(AuthContext);
  const { VITE_TENANT_ID, VITE_TOKEN_TYPE } = getEnvVariables();
  const { setFirebaseToken, setHasNewConnection } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState<{ code: string; error_messages: string } | null | undefined>(null);
  const { SnackBar, setSnackBarMessage } = useSnackBar();
  const { t } = useTranslation();

  useEffect(() => {
    if (isPasswordChanged !== undefined) {
      setSnackBarMessage(t('change_password_success', { ns: 'auth' }));
    }
  }, [isPasswordChanged]);
  const handleLoginSuccessResponse = async (response: LoginResponse) => {
    const menusResponse: AxiosResponse<GetMenusResponse> = await axios.get(
      'https://api-dev.myconcierge.space/api/administration/v1/menus',
      {
        headers: {
          Authorization: `${VITE_TOKEN_TYPE} ${response.access_token}`,
          'x-tenant-id': VITE_TENANT_ID,
        },
      },
    );

    if (menusResponse.data.menus.length > 0) {
      setUser(response.user);
      setHasNewConnection && setHasNewConnection(true);
      setUserStorageData(response);
      localStorage.setItem('menus', JSON.stringify(menusResponse.data.menus));
      setMenus(menusResponse.data.menus as MenuItem[]);
      await handleLanguageOnLogin(response);
      setLoginStatus('authenticated');
      setNoUserAllowedErrorMessage(null);
      prevUrl && setPrevUrl(prevUrl);
    } else {
      setNoUserAllowedErrorMessage({ code: 'no_user_allowed_error_message' });
    }
  };

  const { mutate: onFirebaseLogin } = useMutation(firebaseLogin, {
    onSuccess: async () => {
      // setFirebaseGuid()
    },
    onError: async (err: any) => {
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  const { mutate: onLoginOTP, isLoading: isLoadingLoginOTP } = useMutation(mfaChallenge, {
    onSuccess: async (payload: any) => {
      await handleLoginSuccessResponse(payload.data);
    },
    onError: async (err: any) => {
      setLoginStatus('not-authenticated');
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  const { mutate: onLogin, isLoading } = useMutation(login, {
    onSuccess: async (payload: any, variables: { email: string; password: string }) => {
      if (payload.data?.challenge_session) {
        return navigate('/auth/mfa/otp', {
          state: { session: payload.data?.challenge_session, email: variables?.email },
        });
      }
      await handleLoginSuccessResponse(payload.data);
    },
    onError: async (err) => {
      setLoginStatus('not-authenticated');
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  const { mutate: onLogout } = useMutation(logout, {
    onSuccess: async () => {
      cleanStorage();
      setPrevUrl(null);
      setMenus(null);
      setLoginStatus('not-authenticated');
      navigate('');
      setFirebaseToken('');
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    },
    onError: async (err) => {
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  return {
    onLogin,
    errorMessage,
    isLoading,
    onLogout,
    noUserAllowedErrorMessage,
    SnackBar,
    onFirebaseLogin,
    isLoadingLoginOTP,
    onLoginOTP,
  };
};
